<template>
  <div class="list listeomove">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="contractMenu">
      <el-button @click="exportlist" class="rightMenu" type="text">导出</el-button>
    </div>
    <div class="tabStart" :class="{showclass:tabStartmg}">
      <table-tab :TabData="navData" @tabSwitch="onSwitch" defaultData="1"></table-tab>
    </div>
    <div class="listMenue">
      <list-menu v-if="status == '1'" @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    </div>
    <div class="maxversion">
      <div class="maxverbox">数据版本: {{maxDataVersion}}</div>
      <global-table row-key='id' :Key="status" @SelectionChange='SelectionChange' @SelectAction='SelectAction' :tableField="tableField" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
      ></global-table>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableField1, tableData, checkEd } from './js/setting.js'
import { request, exportForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { MIME } from '@/assets/js/mime.js'
import qs from 'qs'
export default {
  components: Component.components,
  name: 'PurchaseDemand',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 390) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      status: '1',
      tabStartmg: false,
      tableField: [],
      checkEd: checkEd,
      clearData: {},
      tableData: tableData,
      selectTabData: [],
      maxDataVersion: '',
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList, searchData: SearchData },
      navData: [
        {
          label: '待响应',
          name: '1'
        },
        {
          label: '已全部响应',
          name: '2'
        },
        {
          label: '已拒绝',
          name: '3'
        }
      ]
    }
  },
  mounted () {
    // 获取送货基地列表
    var newOption = []
    request('/api/livraison/base/getBaseInfoByName?' + 'baseName=' + '', 'get').then((res) => {
      res.data.forEach(item => {
        newOption.push({
          label: item.baseName,
          value: item.baseCode
        })
      })
      this.childData.searchList[4].option = newOption
    })
  },
  created () {
    this.checkEd.dataLable = '待响应'
    this.tableField = [tableField1, ...tableField]
    this.myBidData()
  },

  methods: {
    // 状态切换查询
    onSwitch: function (data) {
      if (data.props.label === '待响应') {
        this.status = '1'
        this.tabStartmg = false
        this.tableField = [tableField1, ...tableField]
        this.myBidData()
      }
      if (data.props.label === '已全部响应') {
        this.status = '2'
        this.tabStartmg = true
        this.tableField = [...tableField]
        this.myBidData()
      }
      if (data.props.label === '已拒绝') {
        this.status = '3'
        this.tabStartmg = true
        this.tableField = [...tableField]
        this.myBidData()
      }
      this.selectTabData = []
      this.checkEd.data = []
      if (this.clearData.data) {
        this.clearData.clearSelection()
      }
    },
    // 根据状态删除列表第一列多选框
    removeCheck () {
      if (this.status === '2') {
        this.tableField.splice(0, 1)
      }
    },
    // 点击创建送货单按钮
    buttonClick: function (data) {
      if (data.openView === 'openView') {
        if (this.selectTabData.length === 0) {
          this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择需要创建的送货单'
          })
        } else {
          this.$router.push({ params: { viewName: data.alias } })
          this.$emit('OpenView', { openView: data.alias })
          this.$store.commit('setPageValue', this.selectTabData)
          this.checkEd.data = []
          this.clearData.clearSelection()
        }
      }
    },
    // 获取采购交付-送货需求列表数据
    myBidData () {
      let rkksTime = ''
      let rkjsTime = ''
      let rkksTime1 = ''
      let rkjsTime1 = ''
      if (this.childData.searchData.xfTime) {
        rkksTime = dayjs(this.childData.searchData.xfTime[0]).format('YYYY-MM-DD HH:mm:ss')
        rkjsTime = dayjs(this.childData.searchData.xfTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      if (this.childData.searchData.demandTimeST) {
        rkksTime1 = dayjs(this.childData.searchData.demandTimeST[0]).format('YYYY-MM-DD HH:mm:ss')
        rkjsTime1 = dayjs(this.childData.searchData.demandTimeST[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        status: this.status,
        ...this.childData.searchData,
        releaseBeginTime: rkksTime,
        releaseEndTime: rkjsTime,
        demandTimeBeginTime: rkksTime1,
        demandTimeEndTime: rkjsTime1
      }
      this.searchData = obj
      request('/api/livraison/livrasion/getLivrasionDemandDataByUserId', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.maxDataVersion = res.data.maxDataVersion
          this.tableData = res.data.iPage.records
          this.tableData.forEach(item => {
            item.orderDataList = []
          })
          if (this.paginationData.total !== res.data.iPage.total) {
            this.paginationData.total = res.data.iPage.total
          }
        }
      })
    },
    // 获取选中的数据
    SelectionChange (data) {
      this.selectTabData = data
    },
    // 获取当前待响应选中的数据
    SelectAction (selection, row, newthis) {
      this.checkEd.data = selection
      this.clearData = newthis.$refs.table
      if (this.checkEd.dataLable === '待响应') {
        if (selection.length <= 0 ? true : row.baseName === selection[0].baseName) {
          this.selectTabData = selection
        }
      }
    },

    // 查询
    onSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    },
    // 导出
    exportlist () {
      if (this.tableData.length < 1) {
        this.$message.error('先查询出结果再导出')
        return
      }
      var file = { name: '送货需求明细.xls' }
      exportForm('/api/livraison/livrasion/demandExport?' + qs.stringify(this.searchData), 'post').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  }
}
</script>

<style scoped lang="scss">
  .maxversion{
    position: relative;
    .maxverbox{
      font-size: 12px;
      color: #666;
      position: absolute;
      top: -36px;
      right: 30px;
    }
  }
  .listMenue{
    height: 58px;
  }
  .tabStart{
    padding: 0 30px;
    :deep(.el-tabs__header){
      margin: 0;
    }
  }
  // .showclass{
  //   margin-bottom: 20px;
  // }
  .listeomove{
    :deep(.el-table__header .el-checkbox__inner) {
      display: none !important;
    }
  }
  .contractMenu {
    position: relative;
    z-index: 1;
    .el-button {
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 16px;
    }
  }
</style>
