export var SearchList = [
  { labe: '需求单号', code: 'demandOrderCode', type: 'input' },
  {
    labe: '需求类型',
    code: 'demandType',
    type: 'select',
    option: [
      { label: '集采分发', value: '1' },
      { label: '兜底入库', value: '2' },
      { label: '战略入库', value: '3' },
      { label: '备货入库', value: '4' },
      { label: '分发换料', value: '5' },
      { label: '辅料入库', value: '6' },
      { label: '不良补货', value: '7' },
      { label: '计划到货', value: '8' },
      { label: '系统JIT补货', value: '9' },
      { label: '泓泰直送昆山', value: '10' },
      { label: '预配送货', value: '11' }
    ]
  },
  { labe: '物料编号', code: 'materialCode', type: 'input' },
  { labe: '物料描述', code: 'materialDesc', type: 'input' },
  {
    labe: '送货基地',
    code: 'baseCode',
    type: 'select',
    option: []
  },
  { labe: '供应商', code: 'providerInfo', type: 'input', placeholder: '编码/名称' },
  {
    labe: '下发日期',
    code: 'xfTime',
    type: 'daterange'
  },
  { labe: '采购员', code: 'buyer', type: 'input', placeholder: '采购员编码或名称' }
]
export var SearchData = {
  demandOrderCode: '',
  demandType: '',
  materialCode: '',
  materialDesc: '',
  baseCode: '',
  providerInfo: '',
  xfTime: ''
}

export var checkEd = { data: [], dataLable: '' }
export var tableField1 = {
  type: 'input',
  reserveSelection: true,
  selectType: 'selection',
  width: '50',
  selectable: function (data) {
    if (checkEd.dataLable === '待响应') {
      return checkEd.data.length <= 0 ? true : checkEd.data[0].baseName === data.baseName && checkEd.data[0].providerName === data.providerName
    } else {
      return true
    }
  }
}
export var tableField = [
  { label: '需求单号', code: 'demandCode', type: 'input', width: '160' },
  { label: '需求单行号', code: 'demandRow', type: 'input', width: '110' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '110' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '230' },
  { label: '供应商名称', code: 'providerName', type: 'input', width: '220' },
  { label: '需求数量', code: 'demandNumber', type: 'input', width: '100' },
  { label: '待响应数量', code: 'remainNumber', type: 'input', width: '110' },
  { label: '需求送货基地', code: 'baseName', type: 'input', width: '130' },
  { label: '下发日期', code: 'issueTime', type: 'input', width: '110' },
  {
    label: '需求类型',
    code: 'demandType',
    type: 'function',
    width: '90',
    handle: (index, data) => {
      return data.demandType === 1
        ? '集采分发'
        : data.demandType === 2
          ? '兜底入库'
          : data.demandType === 3
            ? '战略入库'
            : data.demandType === 4
              ? '备货入库'
              : data.demandType === 5
                ? '分发换料'
                : data.demandType === 6
                  ? '辅料入库'
                  : data.demandType === 7
                    ? '不良补货'
                    : data.demandType === 8
                      ? '计划到货'
                      : data.demandType === 9
                        ? '系统JIT补货'
                        : data.demandType === 10
                          ? '泓泰直送昆山'
                          : data.demandType === 11
                            ? '预配送货' : ''
    }
  }
]

export var tableData = []
